import { Grid } from "@mui/material";
import React from "react";

function Footer() {
    return (
        <footer className="footer">
            <div id="icon-parent">
            <Grid 
            container
            direction={"row"}
            justifyContent={"center"}
            spacing={3}
            >
                    <Grid 
                    item
                    >
                        <a href="mailto:alexclimbs@gmail.com" className="footer-icon">
                            <h3>
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path></svg>
                            </h3>
                        </a>
                    </Grid>
                    <Grid 
                    item                    
                    >
                        <a href="https://github.com/alexmayberry" target="_blank" rel="noopener noreferrer" className="footer-icon">
                            <h3>
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"></path></svg>
                            </h3>
                        </a>
                    </Grid>
                    <Grid
                    item
                    >                        
                        <a href="https://docs.google.com/document/d/1KGfhUsZhSVNifB4NSOG062JwgrpuReTMK5ChvZBdPkE/edit?usp=sharing" target="_blank" rel="noopener noreferrer" className="footer-icon">
                            <h3>
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M272 41.69V188a4 4 0 004 4h146.31a2 2 0 001.42-3.41L275.41 40.27a2 2 0 00-3.41 1.42z"></path><path d="M248 224a8 8 0 01-8-8V32H92a12 12 0 00-12 12v424a12 12 0 0012 12h328a12 12 0 0012-12V224zm104 160H160v-32h192zm0-80H160v-32h192z"></path></svg>                    
                            </h3>
                        </a>
                    </Grid>
                </Grid>
            </div>
        </footer>
    )
};

export default Footer;