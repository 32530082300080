import React from "react";

function Blog() {

    return (
        <div id="blog" style={{ display:"block", marginLeft:"auto", marginRight:"auto", maxWidth:"80vw" }}>
        <h1>Blog</h1>
        <p>The blog is working on itself right now. Check back later to hear my thoughts on the uses of tech in environmental protection. 🌎🌍🌏</p>
        </div>
    )
}

export default Blog;